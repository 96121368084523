<template>
    <animation class="header" tag="header" name="fadeInDown" :delay="1.5">
        <div class="header__left">
            <logo with-name />

            <locale-select-not-localized class="header__locale-select" />
        </div>

        <div class="header__right">
            <ul>
                <li @click="$emit('scrollTo', 'why')">{{ $t('home.header.nav.why') }}</li>

                <li @click="$emit('scrollTo', 'games')">{{ $t('home.header.nav.games') }}</li>

                <li @click="$emit('scrollTo', 'partners')">{{ $t('home.header.nav.partners') }}</li>
            </ul>

            <div>
                <router-link :to="{ name: 'application' }">
                    <icon-button theme="info">
                        <cart-icon />
                    </icon-button>
                </router-link>

                <router-link :to="{ name: 'login' }">
                    <icon-button>
                        <user-icon v-if="isAuthenticated" />
                        <lock-icon v-else />
                    </icon-button>
                </router-link>
            </div>

            <div>
                <router-link :to="{ name: 'application' }">
                    <text-button theme="info" full>
                        {{ $t('home.header.sell') }}
                        <template #icon>
                            <cart-icon />
                        </template>
                    </text-button>
                </router-link>

                <router-link :to="{ name: 'login' }">
                    <text-button full>
                        {{ $t(isAuthenticated ? 'home.header.account' : 'home.header.authorization') }}
                        <template #icon>
                            <user-icon v-if="isAuthenticated" />
                            <lock-icon v-else />
                        </template>
                    </text-button>
                </router-link>
            </div>
        </div>
    </animation>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Animation from '@/components/Animation';
    import CartIcon from '@/components/icons/CartIcon';
    import IconButton from '@/components/buttons/IconButton';
    import LocaleSelectNotLocalized from '@/components/inputs/LocaleSelectNotLocalized';
    import LockIcon from '@/components/icons/LockIcon';
    import Logo from '@/components/Logo';
    import TextButton from '@/components/buttons/TextButton';
    import UserIcon from '@/components/icons/UserIcon';

    export default {
        name: 'HomeHeader',
        components: {
            Animation,
            CartIcon,
            IconButton,
            LocaleSelectNotLocalized,
            LockIcon,
            Logo,
            TextButton,
            UserIcon,
        },
        computed: {
            ...mapGetters('auth', ['isAuthenticated']),
        },
    };
</script>

<style lang="scss" scoped>
    .header,
    .header__left {
        display: flex;
        align-items: center;
    }

    .header {
        justify-content: space-between;

        .logo {
            width: 6rem;
            height: 6rem;
        }
    }

    .header__right {
        ul,
        div:last-of-type {
            display: none;
        }

        div {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .header__locale-select {
        margin-left: 1rem;
    }

    @media screen and (min-width: 64em) {
        .header .logo::v-deep svg:last-of-type {
            display: block;
        }

        .header__right {
            display: flex;
            align-items: center;

            div {
                &:first-of-type {
                    display: none;
                }

                &:last-of-type {
                    display: grid;
                }
            }

            ul {
                display: flex;

                li {
                    margin-right: 4rem;
                    color: #828aa4;
                    font-family: 'Gotham Book', sans-serif;
                    font-size: 1.4rem;
                    transition: color 0.3s;
                    cursor: pointer;

                    &:hover {
                        color: #ffffff;
                    }
                }
            }
        }

        .header__locale-select {
            margin-left: 2rem;
        }
    }

    @media screen and (min-width: 120em) {
        .header__right ul li {
            font-size: 1.6rem;
        }
    }
</style>
