<template>
    <animation class="footer" tag="footer" name="fadeInUp">
        <p>© SkinPay. {{ $t('home.footer.rights') }}.</p>

        <ul>
            <li>
                <a href="#">{{ $t('home.footer.nav.contacts') }}</a>
            </li>

            <li>
                <a href="#">{{ $t('home.footer.nav.help') }}</a>
            </li>

            <li>
                <a href="#">{{ $t('home.footer.nav.offer') }}</a>
            </li>
        </ul>
    </animation>
</template>

<script>
    import Animation from '@/components/Animation';

    export default {
        name: 'HomeFooter',
        components: {
            Animation,
        },
    };
</script>

<style lang="scss" scoped>
    .footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 7.5rem;

        p {
            color: #b0b8d2;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.4rem;
            font-weight: 325;
            text-align: center;
        }

        ul {
            display: flex;
            margin-bottom: 2rem;

            li {
                &:not(:last-child) {
                    margin-right: 3rem;
                }

                &:hover a {
                    color: #ffffff;
                }
            }

            a {
                color: #828aa4;
                font-family: 'Gotham Book', sans-serif;
                font-size: 1.4rem;
                font-weight: 325;
                transition: color 0.3s;
            }
        }
    }

    @media screen and (min-width: 48em) {
        .footer {
            flex-direction: row;
            justify-content: space-between;

            ul {
                margin: 0;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .footer {
            p {
                font-size: 1.6rem;
            }

            ul a {
                font-size: 1.6rem;
            }
        }
    }
</style>
