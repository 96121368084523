<template>
    <div ref="parallax" class="parallax">
        <slot />
    </div>
</template>

<script>
    import Parallax from 'parallax-js';

    export default {
        name: 'Parallax',
        mounted() {
            new Parallax(this.$refs.parallax);
        },
    };
</script>

<style lang="scss" scoped></style>
