<template>
    <animation name="fadeInUpShort">
        <home-section>
            <div class="promo">
                <parallax>
                    <img src="~@/assets/images/hand-with-diamond.png" alt="Hand with diamond" data-depth-x="0.2" data-depth-y="0.2" />
                </parallax>

                <div class="promo__content">
                    <h1 v-html="$t('home.conditions.title')" />

                    <p>{{ $t('home.conditions.description') }}</p>

                    <router-link :to="{ name: 'application' }">
                        <text-button>
                            {{ $t('home.conditions.button') }}
                            <template #icon>
                                <key-icon />
                            </template>
                        </text-button>
                    </router-link>
                </div>
            </div>
        </home-section>
    </animation>
</template>

<script>
    import Animation from '@/components/Animation';
    import HomeSection from '@/components/home/HomeSection';
    import KeyIcon from '@/components/icons/KeyIcon';
    import Parallax from '@/components/Parallax';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'Promo',
        components: {
            Animation,
            HomeSection,
            KeyIcon,
            Parallax,
            TextButton,
        },
    };
</script>

<style lang="scss" scoped>
    .promo,
    .promo__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .promo img {
        width: 32rem;
    }

    .promo__content {
        h1 {
            margin: 0 !important;
        }

        p {
            max-width: 40rem;
            margin: 1rem 0 2rem;
            color: #99a5c8;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.2rem;
            font-weight: 325;
            line-height: 1.6;
            text-align: center;
        }
    }

    @media screen and (min-width: 40em) {
        .promo img {
            width: 42rem;
        }

        .promo__content p {
            max-width: 50rem;
            margin: 1.5rem 0 2.5rem;
            font-size: 1.4rem;
        }
    }

    @media screen and (min-width: 64em) {
        .promo {
            flex-direction: row;
            justify-content: center;

            img {
                margin-right: 5rem;
            }
        }

        .promo__content {
            align-items: start;

            h1,
            p {
                text-align: left;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .promo img {
            width: 53rem;
        }

        .promo__content p {
            margin: 2rem 0 4rem;
            font-size: 1.7rem;
        }
    }
</style>
