<template>
    <div class="hero">
        <animation class="hero__image" name="fadeInUpShort" :duration="4">
            <parallax>
                <img src="~@/assets/images/skins-factory.png" alt="skins-factory" data-depth-x="0.4" data-depth-y="0.2" />
            </parallax>
        </animation>

        <animation class="hero__content" name="fadeInUpShort" :delay="1">
            <h1>{{ $t('home.hero.title') }}</h1>

            <p>{{ $t('home.hero.description') }}</p>

            <router-link :to="{ name: 'application' }">
                <text-button>
                    {{ $t('home.hero.connect') }}
                    <template #icon>
                        <key-icon />
                    </template>
                </text-button>
            </router-link>
        </animation>
    </div>
</template>

<script>
    import Animation from '@/components/Animation';
    import KeyIcon from '@/components/icons/KeyIcon';
    import Parallax from '@/components/Parallax';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'Hero',
        components: {
            Animation,
            KeyIcon,
            Parallax,
            TextButton,
        },
    };
</script>

<style lang="scss" scoped>
    .hero {
        position: relative;
        padding: 33rem 0 0;
    }

    .hero__image {
        position: absolute;
        top: 2.5rem;
        right: 50%;
        width: 48rem;
        margin-right: -24rem;
        z-index: 0;

        img {
            max-width: 100%;
        }
    }

    .hero__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        z-index: 1;

        h1,
        p {
            max-width: 38rem;
        }

        h1 {
            color: #ffffff;
            font-family: 'Gotham Ultra', sans-serif;
            font-size: 3.5rem;
            font-weight: 500;
            line-height: 1.08;
        }

        p {
            margin: 1.5rem 0 2.5rem;
            color: #99a5c8;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.4rem;
            font-weight: 325;
            line-height: 1.6;
        }

        .button {
            height: 6rem;
            padding: 0 3rem;
        }
    }

    @media screen and (min-width: 40em) {
        .hero {
            padding-top: 43rem;
        }

        .hero__image {
            width: 64rem;
            margin-right: -32rem;
        }

        .hero__content {
            h1 {
                max-width: 50rem;
                font-size: 4.8rem;
            }

            p {
                max-width: 43rem;
                margin: 2rem 0 4rem;
                font-size: 1.6rem;
            }
        }
    }

    @media screen and (min-width: 64em) {
        .hero {
            padding: 14rem 0;
        }

        .hero__image {
            right: -22.5%;
            width: 85%;
            margin: 0;
        }

        .hero__content {
            align-items: start;
            text-align: left;

            h1 {
                max-width: 46rem;
                font-size: 6.2rem;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .hero__content {
            h1,
            p {
                max-width: 53.5rem;
            }

            h1 {
                font-size: 7.2rem;
            }

            p {
                margin: 2.4rem 0 4.8rem;
                font-size: 2rem;
            }

            .button {
                height: 7rem;
            }
        }
    }
</style>
