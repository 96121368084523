<template>
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.9997 3.33325C15.5216 3.33325 11.9076 6.9797 11.9076 11.4981V14.2726H16.0322V11.4981C16.0322 9.27855 17.7999 7.49496 19.9997 7.49496C22.1994 7.49496 23.9671 9.27855 23.9671 11.4981V14.2726H28.0917V11.4981C28.0917 6.9797 24.4385 3.33325 19.9997 3.33325Z"
        />

        <path
            d="M28.4845 15.5012H11.4755C9.74715 15.5012 8.33301 16.9281 8.33301 18.6721V30.2852C8.33301 33.8128 11.1613 36.6665 14.6574 36.6665H25.342C28.8381 36.6665 31.6663 33.8128 31.6663 30.2852V18.6721C31.6663 16.9281 30.2522 15.5012 28.4845 15.5012ZM22.3173 30.0078C22.3959 30.4041 22.1602 30.7212 21.7674 30.7212H18.1927C17.7999 30.7212 17.5642 30.4041 17.6428 30.0078L18.6641 25.3704C17.8785 24.8948 17.3678 24.0625 17.3678 23.0716C17.3678 21.6051 18.5463 20.416 19.9997 20.416C21.4531 20.416 22.6316 21.6051 22.6316 23.0716C22.6316 24.0625 22.1209 24.8948 21.3353 25.3704L22.3173 30.0078Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'LockIcon',
    };
</script>
