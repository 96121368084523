<template>
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.8606 12.54C28.8606 17.2805 25.0597 21.0816 20.3159 21.0816C15.5737 21.0816 11.7712 17.2805 11.7712 12.54C11.7712 7.79949 15.5737 4 20.3159 4C25.0597 4 28.8606 7.79949 28.8606 12.54ZM19.9123 35.9999C12.9112 35.9999 7 34.862 7 30.4718C7 26.0799 12.9484 24.9824 19.9123 24.9824C26.9151 24.9824 32.8247 26.1203 32.8247 30.5105C32.8247 34.9024 26.8763 35.9999 19.9123 35.9999Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'UserIcon',
    };
</script>
